import {
  IColorFilter,
  IDetailCategoryProduct,
  IListSizeConsultation,
  IPriceRange,
  IProductCategoryItem,
  ISizeConsultation,
  ProductCategoryPayload,
  ProductPayload,
  ProductSkuItem,
  ProductSkuPayload,
  SizeConsultationPayload,
  ToggleLikePayload
} from './types';

import axiosInstance from 'services/common/instance';

export const getProductService = async (
  params?: ProductPayload
): Promise<APIPaginationResponse<IProducts[]>> => {
  const res = await axiosInstance.get<APIPaginationResponse<IProducts[]>>('products', { params });
  return res.data;
};

export const getProductDetailService = async (slug: string): Promise<IProducts> => {
  const res = await axiosInstance.get(`products/${slug}`);
  return res.data.data;
};

export const getCategoriesProductService = async (
  params?: ProductCategoryPayload,
): Promise<IProductCategoryItem[]> => {
  const res = await axiosInstance.get('product-categories', { params });
  return res.data.data;
};

export const getColorsProductService = async (): Promise<IColorFilter[]> => {
  const res = await axiosInstance.get('colors');
  return res.data.data;
};

export const getPriceRangesService = async (): Promise<IPriceRange[]> => {
  const res = await axiosInstance.get('price-ranges');
  return res.data.data;
};

export const getSizesProductService = async (): Promise<ISize[]> => {
  const res = await axiosInstance.get('sizes');
  return res.data.data;
};

export const getDetailCategoryProductService = async (
  slug: string
): Promise<IDetailCategoryProduct> => {
  const res = await axiosInstance.get(`product-categories/${slug}`);
  return res.data.data;
};

export const getSizeConsultationService = async (
  id: number,
  params?: SizeConsultationPayload
): Promise<ISizeConsultation> => {
  const res = await axiosInstance.get(`product-categories/${id}/size-consultation`, { params });
  return res.data.data;
};

export const getListSizeConsultationService = async (
  id: number,
): Promise<IListSizeConsultation[]> => {
  const res = await axiosInstance.get(`product-categories/${id}/size-consultations`);
  return res.data.data;
};

export const toggleLikeService = async (
  payload: ToggleLikePayload
): Promise<IProducts> => {
  const { id, ...params } = payload;
  const res = await axiosInstance.post(`products/${id}/toggle-like`, params);
  return res.data.data;
};

export const getProductViewedService = async (
  params?: ProductPayload
): Promise<APIPaginationResponse<IProducts[]>> => {
  const res = await axiosInstance.get<APIPaginationResponse<IProducts[]>>('products/viewed', { params });
  return res.data;
};

export const getProductFavorite = async (
  params?: ProductPayload
): Promise<APIPaginationResponse<IProducts[]>> => {
  const res = await axiosInstance.get('products/favourite', { params });
  return res.data;
};

export const getProductSku = async (
  params: ProductSkuPayload
): Promise<ProductSkuItem[]> => {
  const res = await axiosInstance.get('products/skus', { params });
  return res.data.data;
};

export const checkInStock = async (
  itemCode?: string
): Promise<boolean> => {
  if (itemCode) {
    const res = await axiosInstance.get(`products/${itemCode}?CheckInStock=true`);
    return res.data.inStock;
  }
  return false;
};
